import { LOCAL_STORAGE_KEYS } from "@helpers/enums/local-storage-keys";
import { getItemFromLocalStorage } from "@helpers/miscelleanous";
import { getUserLevelNudges } from "@services/users.service";
import { useEffect, useState } from "react";

/*
       ** useUserLevelNudges **
       This hook is used to perform functional operation on user-level nudges
       
       The hook returns nudgesState and a set of helper methods.


       ** useEffect **
       Called on first load to get nudges and save it to local storage and nudgesState.
       on Reload, fetches the nudges from local storage and save in nudgesState. If nudges is removed from local storage, it will fetch the nudges using api call

       ** nudgesState **
       nudges: stores array of user-level nudges.{nudgeType and data}
       loading: is used during fetching nudges.

       Example: nudgesState: {
           nudges: [
               {nudgeType: 'EmailVerification', data: {user: {id:'1234-27827', name: 'john', email:'john@gmail.com', mobile:'919987656554', countryCode: '+91'}}},
              ].
          loading: false
       }

       ** Helper methods **
       isNudgePresent: checks whether a nudge of a particular nudgeType is present in the nudgesState
        getNudgeData: returns the data associated with the specified nudgeType,
        setNudgeSuccessful: removes the specified nudgeType from nudgesState and localStorage. called when 
        the nudge is completed,
        */

const useUserLevelNudges = ({ userId }) => {
    const [nudgesState, setNudgesState] = useState({
        nudges: [],
        loading: false,
    });
    const localStorageNudgesKey = LOCAL_STORAGE_KEYS.UserLevelNudges;

    useEffect(() => {
        const pendingNudges = getItemFromLocalStorage(localStorageNudgesKey);
        if (!pendingNudges) {
            if (userId) {
                getNudges(userId);
            }
        } else {
            setNudgesState({
                nudges: pendingNudges,
                loading: false,
            });
        }
    }, []);

    const getNudges = async (userId) => {
        setNudgesState((curVal) => ({ ...curVal, loading: true }));
        const response = await getUserLevelNudges(userId);
        if (response?.status && Array.isArray(response.entity)) {
            localStorage.setItem(
                localStorageNudgesKey,
                JSON.stringify(response.entity)
            );
            setNudgesState({ nudges: response.entity, loading: false });
            return { nudges: response.entity, loading: false };
        } else {
            setNudgesState((curVal) => ({ ...curVal, loading: false }));
            return { nudges: [], loading: false };
        }
    };

    const fetchNudges = async (userId) => {
        return await getNudges(userId);
    };

    const isNudgePresent = (nudgeType) =>
        nudgesState.nudges?.findIndex(
            (nudge) => nudge.nudgeType === nudgeType
        ) >= 0;

    const getNudgeData = (nudgeType) => nudgesState.nudges?.find(nudge => nudge.nudgeType === nudgeType)?.data;

    const setNudgeSuccessful = (nudgeType) => {
        /** removes the successful nudge from the list of nudges */
        const updatedNudges = nudgesState.nudges.filter(nudge => nudge.nudgeType !== nudgeType);
        setNudgesState({ nudges: updatedNudges, loading: false });
        localStorage.setItem(localStorageNudgesKey, JSON.stringify(updatedNudges));
    }

    return {
        nudgesState,
        isNudgePresent,
        getNudgeData,
        fetchNudges,
        setNudgeSuccessful,
    };
}

export default useUserLevelNudges;