import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import { useEffect, useRef, useState, useContext } from 'react';
import {
    getLoginSchema,
    mobileNumberLength,
    phoneInputStyle,
} from '../utils/helper';
import 'react-phone-input-2/lib/style.css';
import { validate } from '@services/users.service';
import { Input, Link, Text } from '@ui/atoms';
import Checkbox from '@ui/atoms/Checkbox';
import {
    getReferralCode,
    removeReferralCode,
    removeReferralEntityType,
} from '@services/identity.service';
import { checkReferralCode } from '@services/referral.service';
import ConfirmationSignUpModal from '../ConfirmationSignUpModal';
import { sendOtp } from '@services/session.service';
import texts from './LoginModalData/en.json';
import { useRouter } from 'next/router';
import { userCountryCodesInput, isValidMobileNumber, investmentTimeFrameOption } from '@helpers/constants';
import { SUPPORT_EMAIL } from '@ui/lib/helpers';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import Select from 'react-select';
import { theme } from 'tailwind.config';
import { KYC_FLOW_SELECT_STYLES } from '@ui/components/Kyc/helpers';
import { OtpContext } from '@ui/helpers/enums/otp-context';
import Button from '@ui/atoms/design-system/Button';
import { loginSignupEventsName } from '@helpers/ga-events-name-enum';
import AuthContext from '@context/auth';
const initialAccountState = {
    isActive: true,
    multipleAccount: null,
    multipleLogin: false,
};

const LoginModalForm = ({
    showEmailInput,
    setShowEmailInput,
    fromOpportunitySignup = false,
    handleCloseModal,
    checkReferral = true,
    userInput,
    setUserInput,
    showSignUp,
    setShowSignUp,
    setShowVerifyOtpModal,
    isStatic = false,
    resetForm = false,
    mobileRef,
    emailRef,
    setResetForm = (_) => null,
    setOtpModes,
    setModalLoadingState
}) => {
    const router = useRouter();
    const [account, setAccount] = useState(initialAccountState);
    const [otpMessage, setOtpMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [mobileErrorMessage, setMobileErrorMessage] = useState('');
    const nonInputRef = useRef(null);
    const [userData, setUserData] = useState({}); // [TODO] - check if this is required
    const [userReferralCode, setUserReferralCode] = useState(''); // [TODO] - check if this is required
    const [countryCode, setCountryCode] = useState(userInput?.countryCode?.substr(1) || '91');
    const [createNewAccountLoading, setCreateNewAccountLoading] = useState(false);
    const [hasTyped, setHasTyped] = useState(false);

    const {
        signUpModal, loginModal
    } = useContext(AuthContext);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        reset,
        clearErrors,
        formState: { errors },
        watch
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(getLoginSchema(showEmailInput)),
        defaultValues: {
            phone: '',
            name: '',
            email: '',
            country: '91',
            whatsappOptIn: true,
            investmentTimeFrame: ''
        },
    });
    
    const handleFormInput = () => { 
        if (!hasTyped) {
            setHasTyped(true);
            triggerCustomGaEvent(loginSignupEventsName.SIGNUP_FORM_START);
        }
    }

    const onSubmit = async (data) => {
        setLoading(true);
        setModalLoadingState(true);
        if (!checkReferral) {
            const referralCode = getReferralCode();
            if (referralCode) {
                const referralCodeData = await checkReferralCode(referralCode);
                if (!referralCodeData.status) {
                    removeReferralCode();
                    removeReferralEntityType();
                }
            }
        }
        await submitData(data);
        setLoading(false);
        setModalLoadingState(false);
    };

    const submitData = async (data) => {
        const referralCode = getReferralCode();
        if (referralCode) {
            const referralCodeData = await checkReferralCode(referralCode);
            if (!referralCodeData.status) {
                setUserData(data);
                setUserReferralCode(referralCode);
                setShowConfirmationModal(true);
            } else {
                await signUp(data, referralCode);
            }
        } else {
            await signUp(data);
        }
    };

    const signUp = async (data, referralCode) => {
        const { phone, name, email, country, whatsappOptIn, investmentTimeFrame } = data;
        const countryCodeWithPrefix = `+${country}`;
        const payload = {
            mobile: phone.trim(),
            name: name ? name.trim() : false,
            email: email.trim(),
            countryCode: countryCodeWithPrefix,
            whatsappOptIn,
            userExists: !showEmailInput,
            investmentTimeFrame
        }
        setUserInput({
            ...payload,
            referralCode
        });
        setLoading(true);
        setModalLoadingState(true);
        const response = await Promise.all(
            showEmailInput ?
                //signup
                country === '91'
                    ?
                    [
                        sendOtp({
                            mobile: payload.mobile,
                            countryCode: countryCodeWithPrefix,
                            context: OtpContext.MOBILE_VERIFICATION
                        }),
                        sendOtp({
                            mobile: payload.mobile,
                            email: payload.email,
                            context: OtpContext.EMAIL_VERIFICATION
                        })
                    ]
                    : [
                        sendOtp({
                            mobile: payload.mobile,
                            email: payload.email,
                            context: OtpContext.EMAIL_VERIFICATION
                        })
                    ] :
                //Login
                [
                    sendOtp({
                        mobile: payload.mobile,
                        context: OtpContext.AUTHENTICATION
                    })
                ]);
        if (response.every(result => result.status)) {
            triggerCustomGaEvent(showEmailInput ? loginSignupEventsName.SIGNUP : loginSignupEventsName.LOGIN, { mobile: phone, name, email, investmentTimeFrame });
            setShowVerifyOtpModal(true);
            setOtpMessage(false);
            if (!showEmailInput) {
                setOtpModes(response[0].entity);
            }
        } else {
            setOtpMessage((response.find(res => res.message))?.message);
        }
        setLoading(false);
        setModalLoadingState(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && e?.target?.selectionStart === 0 && e?.target?.selectionEnd === e?.target?.value?.length) {
            // If backspace is pressed and the whole input is selected
            e.preventDefault(); // Prevent the default behavior
            // Clear only the number portion of the input
            setValue('phone', `${getValues('country')}`);
        }
        if (e.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    const onError = (e) => console.error('error', e);

    const handlePhoneChange = async (value, country) => {
        handleFormInput();
        const previousPhone = getValues('phone');
        setValue('country', country.dialCode); // Update the selected country in the form state
        if (countryCode !== country.dialCode) {
            setCountryCode(country.dialCode);
            setValue('phone', country.dialCode);
        } else {
            setValue('phone', value, {
                shouldValidate: errors?.phone
            }); // Update the value in the form state
        }
        if (value !== previousPhone) {
            await checkMobileValidity(value, country.dialCode);
        }
    };

    const checkMobileValidity = async (phone, countryCode) => {
        const isValid = isValidMobileNumber(phone, {
            countryCode,
        });
        setMobileErrorMessage('');
        if (isValid) {
            // Perform your API call here
            const response = await validate(phone);

            setShowEmailInput(!response.status);
            if (response.status) {
                if (response.entity?.isActive) {
                    setAccount({ ...response?.entity });
                    if (!response.entity.multipleAccount) {
                        setShowSignUp(false);
                    }
                    setMobileErrorMessage('');
                } else {
                    setAccount({ ...initialAccountState, isActive: false });
                    setMobileErrorMessage(
                        `Your account has been deactivated. Please get in touch with ${SUPPORT_EMAIL} for any queries`
                    );
                }
            } else {
                setShowEmailInput(true);
                setAccount({ ...initialAccountState, multipleAccount: false })
            }
        } else {
            if (showSignUp) {
                setShowEmailInput(true);
            } else {
                setAccount(initialAccountState);
            }
        }
    }

    const resetSignUpFields = (data) => {
        const countryCode = getValues('country');
        setValue('phone', data?.mobile ? data.mobile : `${countryCode || '91'}`);
        setValue('email', data?.email || '');
        setValue('name', data?.name || '');
        setValue('whatsappOptIn', data?.whatsappOptIn === false ? false : true);
        setValue('investmentTimeFrame', data?.investmentTimeFrame || '');
        clearErrors();
    }

    const resetSignInFields = async (data) => {
        const countryCode = getValues('country');
        setValue('phone', data?.mobile ? data.mobile : `${countryCode || '91'}`);
        if (data?.mobile && data.countryCode) {
            await checkMobileValidity(data.mobile, data.countryCode.substr(1));
        }
        clearErrors();
    }

    const resetFormFields = () => {
        const countryCode = getValues('country');
        reset();
        setTimeout(() => {
            setValue('phone', `${countryCode || '91'}`);
        }, 0);
    }

    const handleLoginToExistingAccount = () => {
        setShowEmailInput(false);
        if(!fromOpportunitySignup) loginModal();
        resetFormFields();
    };

    const handleSignup = () => {
        if(!fromOpportunitySignup) signUpModal();
        setAccount(initialAccountState);
        setShowEmailInput(true);
        setShowSignUp(true);
        resetFormFields();
    };

    const isMobileValid = () => {
        const { phone, country } = getValues();
        const isValid =
            phone.slice(country?.length)?.length ===
            mobileNumberLength(country);
        return isValid;
    };

    const handleCreateNewAccount = async () => {
        const { phone, country } = getValues();
        if (isMobileValid()) {
            setCreateNewAccountLoading(true);
            setModalLoadingState(true);
            await router.push(
                `/create-new-account?mobile=${phone}&countryCode=${country}`
            );
            handleCloseModal?.call();
            setCreateNewAccountLoading(false);
            setModalLoadingState(false);
        }
    };

    const onSwitchLoginAndSignup = () => {
        if (isMobileValid()) {
            setMobileErrorMessage('');
        }
        if (showEmailInput) {
            handleLoginToExistingAccount();
        } else {
            handleSignup();
        }
    };

    const mobileValidation = (value, country) => {
        const isValid = isValidMobileNumber(value, country);
        return isValid;
    };

    useEffect(() => {
        /* resetting form on static page when we move login to signup or signup to login by cta */
        if (resetForm && isStatic) {
            if (showEmailInput) resetSignUpFields();
            else resetSignInFields();
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        (async () => {
            if (userInput) {
                if (showEmailInput) resetSignUpFields(userInput);
                else await resetSignInFields(userInput);
            }
        })();
    }, []);

    useWatch({ control });

    return (
        <div
            className={`pb-0 ${isStatic
                ? fromOpportunitySignup
                    ? 'xl:pb-4'
                    : 'xl:pb-0'
                : 'xl:pb-1'
                }`}
            ref={nonInputRef}
        >
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div>
                    <label>
                        <Text
                            className={`w-full mb-2 p4-medium text-left ${isStatic ? 'text-gray-900' : 'text-basicBlack'
                                }`}
                            content={texts.MobileNumber}
                        />
                    </label>
                    <Controller
                        control={control}
                        name="phone"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <PhoneInput
                                {...field} // Register the 'phone' field with react-hook-form
                                country={'in'}
                                disableDropdown={true}
                                onChange={handlePhoneChange}
                                onKeyDown={handleKeyDown}
                                ref={mobileRef}
                                inputRef={mobileRef}
                                inputMode="none"
                                autoComplete="tel"
                                placeholder={texts.EnterPhoneNumber}
                                onlyCountries={userCountryCodesInput}
                                containerStyle={
                                    phoneInputStyle(isStatic).containerStyle
                                }
                                value={field.value}
                                countryCodeEditable={false}

                                inputStyle={
                                    phoneInputStyle(isStatic).inputStyle
                                }
                                buttonStyle={
                                    phoneInputStyle(isStatic).buttonStyle
                                }
                                dropdownStyle={
                                    phoneInputStyle(isStatic).dropdownStyle
                                }
                                dropdownClass={
                                    'w-full country-list-container position-fixed'
                                }
                                inputProps={{
                                    ref: mobileRef,
                                    required: true,
                                    id: 'phone',
                                    autoFocus: true,
                                }}
                            />
                        )}
                    />
                </div>
                {mobileErrorMessage && (
                    <div className="my-2 text-semantic-error-base p5-regular">
                        {mobileErrorMessage}
                    </div>
                )}
                {errors?.phone && (
                    <p className="my-2 text-semantic-error-base p5-regular">
                        {errors.phone?.message}
                    </p>
                )}
                {account?.multipleAccount && showSignUp && !showEmailInput && (
                    <Text
                        content={texts.MultipleAccountDesc}
                        className="my-4 text-gray-500 md:my-6 p5-regular md:p4-regular"
                    />
                )}
                {showEmailInput && (
                    <>
                        <div className="mt-3">
                            <Input
                                register={register}
                                labelClass={`w-full text-left text-gray-900 !p4-medium`}
                                label={texts.Name}
                                id={'name'}
                                type={'text'}
                                autoComplete="name"
                                placeholder="Enter your name"
                                error={errors?.name}
                                borderWeight="border-1"
                                bgStyle="bg-basicWhite"
                                focusStyle="focus:ring-0"
                                onKeyPress={ handleFormInput }
                            />
                        </div>
                        <div className="mt-3">
                            <Input
                                register={register}
                                labelClass={`w-full !p4-medium text-left text-gray-900`}
                                id="email"
                                type="email"
                                inputRef={emailRef}
                                label={texts.Email}
                                placeholder={texts.EnterYourEmail}
                                error={errors?.email}
                                autoComplete="email"
                                borderWeight="border-1"
                                bgStyle="bg-basicWhite"
                                focusStyle="focus:ring-0"
                                onKeyPress={ handleFormInput }
                            />
                        </div>
                        <div className="my-3 flex flex-col gap-2">
                            <label className="w-full !p4-medium text-left text-gray-900">
                                {texts?.WhenAreYouPlaningToInvest}
                            </label>
                            <Controller
                                name="investmentTimeFrame"
                                control={control}
                                rules={{ required: 'required' }}
                                render={({ field, fieldState }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            instanceId="investmentTimeFrame"
                                            label={texts?.WhenAreYouPlaningToInvest}
                                            options={investmentTimeFrameOption}
                                            styles={{
                                                ...KYC_FLOW_SELECT_STYLES,

                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    fontWeight: '400',
                                                    color: theme?.colors?.gray?.[500],
                                                    fontSize: '14px',
                                                    paddingLeft: '5px'
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    fontWeight: '400',
                                                    color: theme?.colors?.gray?.[800],
                                                    paddingLeft: '5px'
                                                }),
                                                option: (styles, { isSelected, isFocused }) => ({
                                                    ...styles,
                                                    backgroundColor: isSelected
                                                        ? theme?.colors?.primary?.[500]
                                                        : isFocused ? theme?.colors?.primary?.[500] : theme?.colors?.basicWhite,
                                                    color: isFocused ? theme?.colors?.basicWhite : ''
                                                }),
                                                dropdownIndicator: (base, { selectProps }) => ({
                                                    ...base,
                                                    transition: 'all .4s ease',
                                                    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null
                                                }),
                                                control: (base, state) => ({
                                                    ...base,
                                                    minHeight: '48px',
                                                    borderRadius: '8px',
                                                    fontSize: '0.875rem',
                                                    border: state.isFocused ? '1px solid #003975' : base.border,
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #003975',
                                                    }
                                                })

                                            }}
                                            isSearchable={false}
                                            onChange={(event) => {
                                                handleFormInput();
                                                setValue('investmentTimeFrame', event.value, { shouldValidate: true })
                                            }}
                                            value={investmentTimeFrameOption.find(
                                                (option) => option.value === getValues('investmentTimeFrame')
                                            ) || 'Select'}
                                            placeholder={texts?.SelectAnyOneOption}
                                        />
                                        {fieldState.error && (
                                            <p className="my-2 text-semantic-error-base p5-regular">
                                                {fieldState?.error?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div
                            className={`text-sm text-left flex flex-col gap-2 mt-3 ${isStatic ? '' : 'xl:my-2'
                                }`}
                        >
                            {/* Whatsapp OptIn */}
                            <div className="flex items-center">
                                <Checkbox
                                    register={register}
                                    className={`form-check-input border border-gray-200 ml-0 p-[1px] md:p-0.5 bg-basicWhite ${isStatic
                                        ? 'checked:bg-primary-500'
                                        : 'checked:bg-primary-200 checked:border-primary-500'
                                        } focus:outline-none transition duration-200 align-top mr-2 cursor-pointer`}
                                    labelClass={`${isStatic
                                        ? 'p5-regular text-gray-900'
                                        : 'p5-regular text-gray-900'
                                        } `}
                                    height="h-4"
                                    width="w-4"
                                    roundedClass="rounded-[4px]"
                                    label={texts.ReceiveUpdatesOnWhatsApp}
                                    id="whatsappOptIn"
                                    type="checkbox"
                                    defaultChecked={false}
                                    wrapperclass={'!py-0'}
                                    gtmtag={'gtm-click-whatsapp-tick-btn'}
                                />
                            </div>

                            {!isStatic ? (
                                <div className="p5-regular">
                                    <div>
                                        By Signing up, you confirm that you are
                                        above 18 years of age and that you are a
                                        citizen of India
                                    </div>

                                    <div className="pt-4 md:py-4 xl:py-2">
                                        {"By Signing up, you agree to the "}
                                        <Link
                                            href="./terms-and-condition"
                                            className="pr-1 text-primary-500 p5-underline"
                                            target="_blank"
                                            children="Terms & Conditions"
                                        />
                                        {texts.And}
                                        <Link
                                            href="./privacy-policy"
                                            className="pl-1 text-primary-500 p5-underline"
                                            target="_blank"
                                            children="Privacy Policy"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="text-gray-600 p5-regular">
                                    <Text
                                        content={texts.BySigningUp}
                                        className=""
                                    />

                                    <ul className="ml-6 list-disc marker:text-gray-400">
                                        <li className="mt-1">
                                            {texts.ConfirmAgeText}
                                        </li>
                                        <li className="mt-1">
                                            {texts.YouAgreeToThe}
                                            <Link
                                                href="/terms-and-condition"
                                                className="px-1 p5-underline text-primary-500"
                                                target="_blank"
                                                children={
                                                    texts.TermsAndConditions
                                                }
                                            />
                                            and
                                            <Link
                                                href="/privacy-policy"
                                                className="pl-1 p5-underline text-primary-500"
                                                target="_blank"
                                                children={texts.PrivacyPolicy}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {account?.multipleAccount && showSignUp && !showEmailInput && (
                    <Button
                        id="loginButton"
                        buttonSize="large"
                        widthClass='w-full'
                        isLoading={loading}
                        disabled={createNewAccountLoading}
                        onClick={() => {
                            if (isMobileValid()) {
                                setShowEmailInput(false);
                                setShowSignUp(false);
                            }
                        }}
                        buttonText="Login to an existing account"
                    />
                )}
                {(!showSignUp || !account?.multipleAccount || showEmailInput) && (
                    <div className="mt-3 text-center">
                        <Button
                            id="loginAndSignUpButton"
                            isLoading={loading}
                            buttonText={showEmailInput ? 'Sign Up' : 'Login'
                            }
                            disabled={showEmailInput
                                ? false
                                : !account.isActive
                            }
                            buttonSize='large'
                            type='submit'
                            widthClass='w-full'
                        />
                        {otpMessage && (
                            <Text
                                content={otpMessage}
                                weight="500"
                                className="mt-2 text-semantic-error-base"
                            />
                        )}
                    </div>
                )}

                {account?.multipleAccount && showSignUp && !showEmailInput && (
                    <Button
                        id="createNewAccountButton"
                        buttonSize="large"
                        buttonType="secondary"
                        className="mt-3"
                        onClick={handleCreateNewAccount}
                        isLoading={createNewAccountLoading}
                        disabled={loading}
                        buttonText="Create a new account"
                        widthClass='w-full'
                    />
                )}
                {!isStatic && !showEmailInput && !showSignUp ? (
                    <div className='flex items-center justify-center mt-4'>
                        <Text
                            content={showEmailInput
                                ? texts.AlreadyHaveAnAccount
                                : texts.NewToJiraaf}
                            className=" text-gray-600 p4-regular mr-1"
                        />
                        <Button
                            buttonType='text'
                            buttonSize='small'
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={onSwitchLoginAndSignup}
                            type='button'
                            buttonText={showEmailInput
                                ? texts.Login
                                : texts.SignUp}
                        />
                    </div>
                ) : null}
                {fromOpportunitySignup && (
                    <>
                        <div className="mt-4 text-gray-600 md:mt-6 p4-regular">
                            {showEmailInput
                                ? texts.AlreadyHaveAnAccount
                                : texts.CreateAnAccount}
                            <span
                                className="pl-1 cursor-pointer text-primary-600 p4-underline"
                                onClick={
                                    showEmailInput
                                        ? handleLoginToExistingAccount
                                        : handleSignup
                                }
                            >
                                {showEmailInput ? texts.Login : texts?.SignUp}
                            </span>
                        </div>
                    </>
                )}
            </form>
            {!isStatic && !account?.multipleAccount && showEmailInput ? (
                <>
                    <div className='flex items-center justify-center mt-4'>
                        <Text
                            content={texts.AlreadyHaveAnAccount}
                            className=" text-gray-600 p4-regular mr-1"
                        />
                        <Button
                            buttonType='text'
                            buttonSize='small'
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={onSwitchLoginAndSignup}
                            type='button'
                            buttonText={texts.Login}
                        />
                    </div>
                </>
            ) : null}
            {showConfirmationModal && (
                <ConfirmationSignUpModal
                    show={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    message="Your referral link is not valid. Would you like to continue?"
                    signUp={signUp}
                    setLoading={setLoading}
                    removeReferralCode={removeReferralCode}
                    userData={userData}
                    referralCode={userReferralCode}
                />
            )}
        </div>
    );
};

export default LoginModalForm;
