const defaultTheme = require("tailwindcss/defaultTheme");
const colorConstants = require("./helpers/color-constants");


module.exports = {
    content: [
        './atoms/*.{js,jsx}',
        './molecules/*.{js,jsx}',
        './molecules/**/*.{js,jsx}',
        './organisms/*.{js,jsx}',
        './organisms/**/*.{js,jsx}',
        './templates/*.{js,jsx}',
        './templates/**/*.{js,jsx}',
        '../node_modules/@material-tailwind/react/components/**/*.{js,ts,jsx,tsx}',
        '../node_modules/@material-tailwind/react/theme/components/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
        whitelist: ['.grid', '.grid-cols-3', '.gap-x-5', 'md'],
        safelist: [
            {
                variants: ['.text-5xl', 'sm', 'lg', 'xl', 'md'],
            },
        ],
    },
    darkMode: false, // or 'media' or 'class'
    theme: {
        colors: {
            /* IMPORTANT: any new color from the design system 
            to be added to helpers/color-constants.js file. 
            Do not add directly here */
            ...colorConstants,
        },
        extend: {
            gridTemplateColumns: {
                13: 'repeat(13,minmax(0,1fr))',
            },
            screens: {
                mobile: {
                    min: '320px',
                    max: '425px',
                },
                xs: { min: '425px', max: '640px' },
                ...defaultTheme.screens,
            },
            zIndex: {
                '-1': '-1',
            },
            listStyleType: {
                roman: 'lower-roman',
                alpha: 'lower-alpha',
            },
            grayscale: {
                100: '100%',
            },
            inset: (theme) => ({
                '1/5': '20%',
                '1/10': '10%',
                '1/20': '4.3%',
                10.5: '2.625rem',
                8.5: '2.125rem',
                24.5: '6.3rem',
                200: '50rem',
            }),
            margin: (theme) => ({
                22: '5.5rem',
                15: '3.75rem',
                13: '2.188rem',
                15.5: '4.688rem',
            }),

            padding: (theme) => ({
                'screen-90': '80vh',
                'screen/1.3': '75vh',
                'screen/2': '50vh',
                'screen/3': 'calc(100vh / 3)',
                'screen/4': 'calc(100vh / 4)',
                'screen/5': 'calc(100vh / 5)',
                4.5: '1.125rem',
                '52px': '3.25rem',
                18: '4.5rem',
                53: '13.25rem',
                22: '5.5rem',
                '18px': '1.125rem',
                '34px': '2.125rem',
                17: '4.25rem',
                19: '4.75rem',
                26: '6.5rem',
                14: '3.625rem',
                34: '8.5rem',
                46: '11.813rem',
                62: '15.875rem',
                18.5: '4.625rem',
                114.5: '28.625rem',
            }),
            height: (theme) => ({
                17: '4.25rem',
                75: '18.75rem',
                200: '50rem',
                127: '508px',
                138: '552px',
                121: '30.25rem',
                107: '26.75rem',
                '95%': '95%',
                85: '21.25rem',
                section: '92vh',
            }),
            borderWidth: (theme) => ({
                1: '1px',
            }),
            borderRadius: (theme) => ({
                '10px': '0.625rem',
                '7px': '0.438rem',
                '20px': '1.125rem',
                '40px': '2.5rem',
                '80px': '5rem',
            }),
            maxWidth: {
                60: '12rem',
                70: '17.5rem',
                316: '79rem',
                745: '745px',
            },
            boxShadow: {
                0.5: '0px 2px 8px rgba(0, 0, 0, 0.08)',
                0.19: ' 0px 12px 24px rgba(11, 7, 110, 0.04)',
            },
            fontFamily: {
                inter: ['Inter', 'sans-serif'],
                Neue: ['Bebas Neue', 'cursive'],
                montserrat: ['Montserrat', 'sans-serif'],
                bahnschrift: ['Bahnschrift', 'sans-serif'],
            },
            spacing: {
                360: '5.625rem',
                122: '30.625rem',
                60: '3.75rem',
            },
            fontWeight: (theme) => ({
                normal: '400',
                aboveNormal: '450',
            }),
            fontSize: (theme) => ({
                small: '0.6875rem', //11px,
                'title:size': [33, 36],
                '18px': '0.938rem',
                '28px': '1.75rem',
                '32px': '2rem', //32px
                '3xl': '2rem', //32px
                '4xl': '2.5rem', //40px
                '5xl': '3rem', //48px
                '6xl': '3.5rem', //56px
                '7xl': '4rem', //64px
                '8xl': '4.5rem', //72px
            }),
            container: {
                padding: {
                    xl: '2rem',
                },
            },
            width: {
                17: '4.25rem',
                250: '1000',
                333: '83rem',
                120: '30rem',
                140: '35rem',
                316: '79rem',
                1010: '63.125rem',
                688: '43rem',
                1641: '102.56343rem',
            },
            minWidth: {
                50: '12.5rem',
                55: '13.75rem',
                85.75: '21.438rem',
                150: '37.5rem',
            },
            minHeight: {
                12: '3rem',
                25: '6.25rem',
                400: '400px',
            },
            maxHeight: {
                section: '80vh',
            },
            lineHeight: {
                32: '8rem',
            },
            flex: {
                3: '1 0 33.33%',
            },
        },
    },
    plugins: [],
};
