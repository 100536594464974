import { Text } from '@atoms/index';
import { Modal } from '@ui/atoms';
import Button from '@ui/atoms/design-system/Button';

const MessageModal = ({ title, message, buttonText, onClick, isError, show, onClose }) => <Modal
    show={show}
    onClose={onClose}
    label={title}
    width="md:max-w-[440px] max-w-[328px]"
    height="max-h-[98%]"
    className="px-4 pt-4 md:px-6"
    animation="popIn"
    labelSize={'h5-semibold text-primary-500'}
    labelPadding={'!pb-0 p-4 md:p-6 border-b-0'}
    isError={isError}
    children={
        <div>
            <Text content={message} className="mb-4 text-gray-900 p4-regular" />
            <Button buttonType='primary' buttonSize='large' buttonText={buttonText} onClick={onClick} widthClass='w-full' />
        </div>
    }
/>;

export default MessageModal;