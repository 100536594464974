import { isValidMobileNumber } from '@helpers/constants';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import { theme } from 'tailwind.config';
import * as yup from 'yup';

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const mobileNumberLength = (code) => {
    switch (code) {
        case '971':
            return 9;
        case '65':
            return 8;
        default:
            return 10;
    }
};

export const getLoginSchema = (showEmailInput) => {
    return yup.object().shape({
        country: yup.string(),
        phone: yup
            .string()
            .required('Mobile number is required')
            .test(
                'mobile-required',
                'Mobile number is required',
                (value, props) => {
                    if (value === props.parent?.country) {
                      return false;
                    }
                    return true;
                }
            )
            .test(
                'mobile-length',
                'Please enter a valid mobile number',
                (value, props) => {
                    return isValidMobileNumber(value, {
                        countryCode: props.parent?.country,
                    });
                }
            ),
        ...(showEmailInput
            ? {
                email: yup
                    .string()
                    .required('Email is required')
                    .test(
                        'email-valid',
                        'Valid Email is required',
                        (value) => {
                            return EMAIL_REGEX.test(value);
                        }
                    ),
                name: yup
                    .string()
                    .required('Name is required')
                    .transform((value) => {
                        return value ? value.replace(/^[ ]+|[ ]+$/g, '') : '';
                    })
                    .test('name-valid', 'Name can contain only alphabets and spaces.', (value) => {
                        if (value && typeof value === 'string') {
                            const regex = /^[a-zA-Z][a-zA-Z\s]*$/;
                            return regex.test(value);
                        }
                        return true;
                    })
                    .test('name-valid', 'Valid name is required', (value) => {
                        if (value && typeof value === 'string') {
                            const digitsOnly = value.replace(/\D/g, '');
                            return value.length > 2;
                        }
                        return true;
                    }),
                investmentTimeFrame: yup
                    .string()
                    .required('Please select a valid option.'),
                whatsappOptIn: yup.boolean().default(false),
            }
            : {}),
    });
};

export const phoneInputStyle = (isStatic = false) => {
    const { width } = useWindowDimensions();

    return {
        containerStyle: {
            borderRadius: '8px',
            borderWidth: '1px',
            height: '48px',
            borderColor: theme?.colors?.gray?.[200],
        },
        inputStyle: {
            width: '100%',
            height: '100%',
            borderWidth: '0px',
            paddingLeft: '3rem',
            borderColor: theme?.colors?.gray?.[200],
            backgroundColor: theme?.colors?.basicWhite,
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '14px',
            color: theme?.colors?.gray?.[900],
            fontFamily: 'Montserrat',
        },
        buttonStyle: {
            borderWidth: '0px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            width: '3rem',
            overflow: 'clip',
            backgroundColor: theme?.colors?.basicWhite,
        },
        dropdownStyle: {
            overflow: 'auto',
            height: '185px',
            width: width <= 480 ? (width <= 374 ? '178px' : '233px') : '300px',
        },
    };
};
