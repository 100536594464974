import { theme } from "../../tailwind.config";

export const PROGRESS_POPUP_STATUS = {
    COMPLETED: 'completed',
    PENDING: 'pending'
}
// Deprecated
export const HYPERVERGE_SDK_FILE = 'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.8.3/src/sdk.min.js';

export const HV_SDK_STATUS = {
    AUTO_APPROVED: "auto_approved",
    AUTO_DECLINED: "auto_declined",
    ERROR: "error",
    USER_CANCELED: "user_cancelled"
}

export const PROGRESS_REDIRECTION_TIME = 1500; // 1500 miliseconds

export const PROGRESS_REDIRECTION_TIME_BANK = 4000;

export const INVESTOR_LIVELINESS_STATUS = {
    PENDING: 'Pending',
    MANUAL: 'ManualVerificationRequired',
    VERIFIED: 'Verified',
};

export const KYC_FLOW_SELECT_STYLES = {
    control: (styles, { isDisabled }) => {
        return {
            ...styles,
            color: isDisabled ? theme?.colors?.gray?.[100] : theme?.colors?.gray?.[700],
            backgroundColor: isDisabled ? theme?.colors?.gray?.[100] : theme?.colors?.basicWhite,
            minHeight: '44px',
            cursor: 'pointer',
            fontSize: '14px',
            borderRadius: '8px',
            fontWeight: 400,
            '&:hover': {
                borderColor: theme?.colors?.gray?.[600],
            },
            boxShadow: 'none',
            position: 'relative',
        };
    },
    indicatorSeparator: (state) => ({
        display: 'none',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            fontSize: '14px',
            backgroundColor: isFocused ? theme.colors.primary[500] : theme.colors.basicWhite,
            color: isFocused ? theme.colors.basicWhite : theme.colors.basicBlack,
            zIndex: '999999999999'
        };
    },
};


export const HV_BANK_VERIFICATION_STATUS = {
    MANUAL: 'ManualVerificationRequired',
    VERIFIED: 'Verified',
};